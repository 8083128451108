import React from 'react'
import { Row, Col } from 'react-grid-system';
import styled from 'styled-components';
import { Trans, useTranslation } from 'gatsby-plugin-react-i18next';
import { graphql } from 'gatsby';

import Link from 'widgets/Link';
import Seo from 'components/shared/Seo';
import PageLayout from 'components/Layout/PageLayout';
import Button from 'ui/elements/Button';

const S = {};
S.NotFound = styled.div`
  text-align: center;
  padding: 100px 0;
  
  ul {
    list-style: none;
    margin: 0;
    padding: 0;
  }
`;

S.Header = styled.header`
  h1 {
    font-size: 3.3rem;
    font-weight: 700;
  }
`;

const email = 'openprograms@whu.edu';
const phone = '+49211 44709178';

const NotFoundPage = () => {
  const { t } = useTranslation('404');

  return (
    <PageLayout>
      <Seo title='Not found' />
      <Row align='center' justify='center'>
        <Col xs={8} component={S.NotFound}>
          <S.Header>
            <h1>{t('title')}</h1>
          </S.Header>

          <p>{t('description')}</p>

          <ul>
            <li>
              <Button as={Link} variant='clean' to='/'>
                {t('goToHomepage')}
              </Button>
            </li>
            <li>
              <Button as={Link} variant='clean' to='/open-programs'>
                Open Programs
              </Button>
            </li>
            <li>
              <Button as={Link} variant='clean' to='/customized-programs-for-companies/'>
                Customized Programs
              </Button>
            </li>
            <li>
              <Trans ns='404' i18nKey='contact'>
                Or contact us directly via <Button as='a' variant='clean' href={`mailto: ${email}`}>{{email}}</Button> or <Button as='a' variant='clean' href={`tel: ${phone}`}>{{phone}}</Button>.
              </Trans>
            </li>
          </ul>

        </Col>
      </Row>
    </PageLayout>
  )
}

export const query = graphql`
    query NotFoundPage($language: String!) {
      locales: allLocale(filter: { language: {eq: $language} }) {
        edges {
          node {
            ns
            data
            language
          }
        }
      }
    }
`

export default NotFoundPage;
